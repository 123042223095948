<template>
  <span>Logout</span>
</template>

<script>
import { AUTH_LOGOUT } from '../../../store/modules/auth/keys';

export default {
  name: 'Logout',
  created() {
    this.$store.dispatch(AUTH_LOGOUT).then(() => {
      this.$router.push({ name: 'login' });
    });
  },
};
</script>
